import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./report.scss"
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import { useNavigate } from "react-router-dom";
import { getConsolidateMonthlyList } from "../../redux/features/calendarSlice";
import { resourceList } from "../../redux/features/resourceSlice";
import moment from "moment";
import { Button,FormControl, TextField, Autocomplete, TablePagination, Tooltip, Box } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowBack, Download } from '@mui/icons-material';
import dayjs from 'dayjs';
import TablePaginationActions from "../../components/TablePaginationActions";
import { toast, ToastContainer } from "react-toastify";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { DateRangePicker } from 'rsuite';
import isAfter from 'date-fns/isAfter';
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";

const { REACT_APP_API_BASEURL } = process.env;

const AttendanceReportStatus = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { status, error, consolidateMonthlyList, consolidateHeader } = useSelector(state => state.calender)
    const {data,resourceListDropDown } = useSelector(state => state.resource)
    const [pageSize, setPageSize] = useState(25)
    const [loading, setLoading] = useState(true)
    const [date, setDate] = useState(new Date());
    const [downliadFile, setDownloadFile] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [resourceId, setResourceId] = useState('select resource');
    const [filterQuery, setFilterQuery] = useState(`{"attendance_month":"${moment().format('YYYY-MM')}", "skip":0, "limit":25}`)
    const [limit, setLimit] = useState(25);
    const [dateRange, setDateRange] = useState([null, null])
    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        dispatch(getConsolidateMonthlyList(filterQuery))
        dispatch(resourceList(`{"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setYearAndMonth = (newValue) => {
        const d = new Date(newValue);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        setLoading(true)
        setDate(newValue)
        let filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
        //if (resourceId !== 'select resource' || dateRange) {
        if (resourceId !== 'select resource' && dateRange && dateRange[0]) {
            let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
        } else if (resourceId !== 'select resource') {
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
        } else if (dateRange && dateRange[0]) {
            let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
            filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
        }
        //let filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
        setFilterQuery(filter)
        dispatch(getConsolidateMonthlyList(filter))
        setLoading(true)
        setDate(newValue)
        //} 
        // else {
        //     let filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
        //     dispatch(getConsolidateMonthlyList(filter))
        //     setLoading(true)
        //     setDate(newValue)
        // }
    }
    const updateDateTime = (evt) => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let date_filter = evt && [moment(evt[0]).format('YYYY-MM-DD'), moment(evt[1]).format('YYYY-MM-DD')]
        setDateRange(evt)
        if (resourceId !== 'select resource') {
            let filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            if (evt) {
                filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            }
            setFilterQuery(filter)
            dispatch(getConsolidateMonthlyList(filter))
            setLoading(true)
        } else {
            let filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`

            if (evt) {
                filter = `{"date_range":${JSON.stringify(date_filter)},"attendance_month":${id_date}, "skip":0, "limit":${limit}}`

            }
            setFilterQuery(filter)
            dispatch(getConsolidateMonthlyList(filter))
            setLoading(true)
        }
    }

    if (loading === true && consolidateMonthlyList.data && consolidateMonthlyList.data) {
        setLoading(false)
    }

    const handleChange = (evt) => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
        if (evt) {
            const { name, value } = evt.target || evt;
            setResourceId(value)
            if (name === 'resourceId' && dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filter = `{"filter":{"display_name":{"value":"${value}","operation":"icontains"}}, "date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            } else if (name === 'resourceId') {
                filter = `{"filter":{"display_name":{"value":"${value}","operation":"icontains"}}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            } else if (dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            }
        } else {
            setResourceId('select resource');
            if (dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            }
        }
        setLoading(true)
        setFilterQuery(filter)
        dispatch(getConsolidateMonthlyList(filter))
        // if (evt) {
        //     const { name, value } = evt.target || evt;
        //     if (name === 'resourceId') {
        //         let filter = `{"filter":{"display_name":{"value":"${value}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":${limit}}`
        //         dispatch(getConsolidateMonthlyList(filter))
        //         setResourceId(value);
        //         setLoading(true)
        //     }
        // } else {
        //     setResourceId('select resource');
        //     let filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
        //     dispatch(getConsolidateMonthlyList(filter))
        //     setLoading(true)
        // }
    }

    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
    }
    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        // const d = new Date(date);
        // let year = d.getFullYear();
        // let month = d.getMonth() + 1;
        // let id_date = `"${year}-${month}"`;
        // let filter;
        // if (resourceId !== 'select resource') {
        //     filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":${limit}}`

        // } else {
        //     filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
        // }
        //let newFilterData = JSON.parse(filter);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getConsolidateMonthlyList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        // const d = new Date(date);
        // let year = d.getFullYear();
        // let month = d.getMonth() + 1;
        // let id_date = `"${year}-${month}"`;
        // let filter;
        // if (resourceId !== 'select resource') {
        //     filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":${newPageSize.target.value}}`

        // } else {
        //     filter = `{"attendance_month":${id_date}, "skip":0, "limit":${newPageSize.target.value}}`
        // }
        // let newFilterData = JSON.parse(filter);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getConsolidateMonthlyList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(consolidateMonthlyList.data && consolidateMonthlyList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }


    if (downliadFile === true) {
        let api_url = consolidateMonthlyList && consolidateMonthlyList.data.download_api_url
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
                dispatch(getConsolidateMonthlyList(filterQuery))
                setLoading(true)
            }
        }
    }

    const downloadCsvFile = () => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filter;
        if (resourceId !== 'select resource' && dateRange && dateRange[0]) {
            let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"for_download": True, "no_limit":True}`
        } else
            if (dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"for_download": True, "no_limit":True}`
            } else
                if (resourceId !== 'select resource') {
                    filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "for_download": True, "attendance_month":${id_date}, "no_limit":True}`

                } else {
                    filter = `{"for_download": True, "attendance_month":${id_date}, "no_limit":True}`
                }
        setDownloadFile(true)
        // setFilterQuery(filter)
        dispatch(getConsolidateMonthlyList(filter))
    }

    const onFilterChange = (filterModel) => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filterData;
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue;
            filterData = `{"filter" : {"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
            if (resourceId !== 'select resource' && dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filterData = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}, "${[filterModel.items[0].columnField]}" : {"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}}, "date_range":${JSON.stringify(date_filter)},"attendance_month":${id_date},"skip":0, "limit":${limit}}`

            }
            else if (resourceId !== 'select resource') {
                filterData = `{"or": {"display_name":{"value":"${resourceId}","operation":"icontains"}, "${[filterModel.items[0].columnField]}" : {"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}}, "attendance_month":${id_date}, "skip":0, "limit":${limit}}`

            } else if (dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filterData = `{"filter":{"${[filterModel.items[0].columnField]}" : {"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`

            }
            //  else {
            //     filterData = `{"filter" : {"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
            // }
            //const filterData = `{"filter":{"resource_manager_id":{"value":${user.id},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${pageSize}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getConsolidateMonthlyList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setPageSize(10)
            let filter;
            // if (resourceId !== 'select resource') {
            //     filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date}, "skip":0, "limit":${limit}}`

            // } else {
            //     filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
            // }

            if (resourceId !== 'select resource' && dateRange && dateRange[0]) {
                // let date_filter = { "value": [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')], "operation": "range" }
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]

                filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}},"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            } else if (resourceId !== 'select resource') {
                filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            } else if (dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
            } else {
                filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
            }
            setFilterQuery(filter)
            dispatch(getConsolidateMonthlyList(filter))
        }
        setLoading(true)
    }

    let i = 0
    return (
        <div className="listing">
            <Sidebar />
            <div className="listingContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>Attendance Report Status</h3>
                        <div>
                            <tooltip title={"Download as CSV"}>
                                <button className="btn-add-new" style={{ marginRight: "5px" }} onClick={() => downloadCsvFile()}>
                                    <Download />
                                </button>
                            </tooltip>
                            <RefreshButton api={getConsolidateMonthlyList} filter={filterQuery} style={{ marginRight: 5 }} />
                            <Tooltip title={"Back"}>
                                <button onClick={() => navigate(-1)} className="btn-add-new ">
                                    <ArrowBack />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING) && <LoaderForm />}

                    <div style={{ display: "flex", justifyContent: "space-betwwen", marginTop: "30px", marginBottom: "30px" }}>

                        {/*year and month section*/}
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%", marginTop: "8px", marginRight: "10px" }} >
                            <DatePicker
                                style={{ marginRight: '7px' }}
                                className="date-picker"
                                views={['month', 'year']}
                                label="Year and Month"
                                maxDate={dayjs(moment().format('YYYY-MM-DD'))}
                                value={date}
                                onChange={(newValue) => {
                                    setYearAndMonth(newValue);
                                }}
                                format="YYYY-MM"
                                renderInput={(params) => <TextField disabled={true} {...params} helperText={null} />}
                            />
                        </LocalizationProvider>
                        <FormControl className="date-range-picker">
                            <DateRangePicker
                                format="dd-MM-yyyy"
                                placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
                                showOneCalendar
                                value={dateRange}
                                disabledDate={date => isAfter(date, new Date())}
                                onChange={(evt) => updateDateTime(evt)}
                            />
                        </FormControl>

                        {/*select resource section*/}
                        <FormControl style={{ flexBasis: "70%", marginLeft: "8px" }}>
                            <Autocomplete className="select-resource-drop"
                                id="controllable-states-demo"
                                options={resourceListDropDown && resourceListDropDown.length>0?resourceListDropDown:[]}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                 
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                        {option.display_name} <br/>({option.email})
                                    </Box>
                                   

                                )}
                                onChange={(event, newValue) => {
                                    handleChange(newValue);
                                }}
                                // options={data && data.data && data.data.rows.map((e) => {
                                //     return { name: "resourceId", value: e.display_name, label: e.name + "(" + e.email + ")" }
                                // })}
                                renderInput={(params) => <TextField {...params} label="select resource" />}
                            />
                        </FormControl>

                    </div>
                    {loading === true && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={(consolidateMonthlyList.data && consolidateMonthlyList.data.rows && consolidateMonthlyList.data.rows.length > 0 && consolidateMonthlyList.data.rows) || []}
                        columns={consolidateHeader || []}
                        pageSize={pageSize}
                        getRowId={(row) => row + i++}
                        style={{ fontSize: 16 }}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onFilterModelChange={onFilterChange}
                    />
                </div>

            </div>

        </div>
    )
}
export default AttendanceReportStatus