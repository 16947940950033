import "./manageProject.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../redux/APIS/api";
import { toast, ToastContainer } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import LoaderForm from "../../components/common-function/LoaderForm";
import { assignProjectDomain } from "../../redux/features/domainSlice"
import { getDepartmentList, getDepartmentDomains } from "../../redux/features/departmentSlice"
import { workFlowList } from "../../redux/features/workflowSlice"
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputEditorField, InputMultiSelectField } from "../../components/common-function/InputFields";
import { projectRoleDropDown, skilOption } from "../../redux/features/optionsSlice";
import { RemoveCircleOutline } from "@mui/icons-material";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import {
    getIndustryDepartmentList,
    getIndustryList,
    getAllIndustryList,
} from "../../redux/features/masterSlice";

const { REACT_APP_ENV } = process.env;


const AddProject = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const editFormData = useLocation().state;

    const { departmentDomainsList } = useSelector(state => state.department)
    const { workflows } = useSelector(state => state.workflow)
    const { industry_List, department_List } = useSelector(state => state.master)
    //project role
    const { projectRolesList } = useSelector(state => state.dropdownOptions);
    const [projectRoles, setProjectRoles] = useState([]);
    const [projectResourceformValues, setProjectResourceformValues] = useState({});
    const [selectResourcesArray, setSelectResourcesArray] = useState([]);
    const [selectFilterResource, setSelectFilterResource] = useState([]);
    const [resourcesData, setResourcesData] = useState([]);
    const [assignedResources, setAssignedResources] = useState([]);
    const [inactiveTech, setInactiveTech] = useState("")

    const [formData, setFormData] = useState(
        editFormData ? {
            ...editFormData, project_type_default: { label: editFormData.project_type_name, value: editFormData.project_type_id },
            domains_ids: editFormData?.domains?.map(e => ({ id: e.id, label: e.name, name: "domains_ids", value: e.id })),
            workflow_id: editFormData?.workflow?.id ? { label: editFormData.workflow.name, value: editFormData.workflow.id, name: "workflow_id" } : "",

            department_id: editFormData?.department?.id ? { label: editFormData.department.name, value: editFormData.department.id, name: "department_id" } : "",
            industry: editFormData?.industry?.id ? { label: editFormData.industry.industry_name, value: editFormData.industry.id, name: "industry" } : "",

        }
            : { is_jira_project: false, status: true }
    );
    const [projectTypes, setProjectTypes] = useState([]);
    const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    const userId = rtm_user.user.data.id;

    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState(editFormData && editFormData.description ? editFormData.description : "")
    const { skils } = useSelector(state => state.dropdownOptions)
    const [selectTechnology, setSelectTechnology] = useState("")


    const user = GetLocalstorage.userDetail()
    const userIdd = user && user.id
    const userLable = user && user.name

    useEffect(() => {
        const filterQuery = `{"filter": {"status": {"value": "True", "operation": "eq" } },"order_by":{"column":"-id"},"skip":0, "limit":25}`;
        dispatch(getIndustryDepartmentList(filterQuery));
        dispatch(getIndustryList(`{ "filter": {"status": {"value": "True", "operation": "eq" } },"no_limit": True}`))
        dispatch(getDepartmentList())
        dispatch(getAllIndustryList())
        // dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":1,"operation": "eq"},"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`))
    }, [])

    // Form Change Handler
    const handleDescriptionContent = (evt, name) => {
        var newContent = evt.editor.getData();
        setDescription(newContent)
        //setFormData({ ...formData, [name]: newContent })
    }
    const handleChange = (evt, inputName = null) => {
        if (!Array.isArray(evt)) {
            let { name, type, value, checked } = evt.target || evt;
            value = type === "checkbox" ? checked : type === "time" ? value.length < 8 ? value + ":00" : value : value;
            if (name === "department_id") {
                setFormData({ ...formData, domains_ids: [], [name]: value });
                dispatch(getDepartmentDomains(
                    {
                        "filter": {
                            "status": { "value": "True", "operation": "eq" },

                            "department_id": { "value": evt.value, "operation": "eq" }

                        }, "no_limit": "True"
                    }))
            } else if (name === 'industry') {
                dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${value},"operation": "eq"},"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
                setFormData({ ...formData, [name]: value, department_id: "" })
            }
            else {
                setFormData({ ...formData, [name]: value })
            }
        }
        else
            if (inputName === "domains_ids") {
                setFormData({ ...formData, [inputName]: evt })
            }
            else {
                const optArray = evt.map((e, i) => e.id);
                setFormData({ ...formData, [inputName]: optArray })
            }

    }

    // Form Submit Handler
    const handleSubmit = async (evt) => {
        evt.preventDefault();
        setLoading(true);
        let techArray = []
        if (selectTechnology && selectTechnology.length > 0) {
            selectTechnology.map(e => techArray.push(e.value))
        }
        const submitData = {
            "project_name": formData.project_name,
            "project_type": formData.project_type_id,
            "is_jira_project": formData.is_jira_project,
            "description": description,
            // "project_key": formData.project_key,
            // "industry": formData.industry,
            "industry": formData.industry?.value ? formData.industry.value : formData.industry,
            "department": formData.is_jira_project ? "" : formData.department_id?.value ? formData.department_id.value : formData.department_id,
            "workflow": formData.is_jira_project ? "" : formData.workflow_id?.value ? formData.workflow_id?.value : formData.workflow_id,
            "status": formData.status,
            "technology": techArray,
        }

        const res = formData.id ? await api.put("/projects/main/" + formData.id, { ...submitData, updated_by: userId }) : await api.post("/projects/main", { ...submitData, created_by: userId })
        if (res && res.data && res.data.data) {
            (res.data.data.id && !res.data.data.is_jira_project && formData.domains_ids) && dispatch(assignProjectDomain({
                "project_id": res.data.data.id,
                "domains_ids": formData.domains_ids.map(e => e.value),
                "created_by": userId
            }))
            const assignedResArray = assignedResources.map(e => e.id)
            const projectResourceUpdated = assignedResArray ? await updateProjectResources(formData.id ? formData.id : res.data.data.id) : true;
            if (projectResourceUpdated) {
                toast.success(res.data.message);
            } else {
                toast.success(res.data.message)
            }

            setFormData({ ...formData, is_jira_project: false, status: false });
            setTimeout(() => {
                setDescription("")
                navigate("/all-projects", { replace: true })
                setLoading(false);
            }, 3000);

        } else {
            toast.error(res.data.message)
            setLoading(false);
        }

    }

    const handleChangeOnTechnology = (evt) => {
        let technologyArr = []
        if (evt.length > 0) {
            evt.map(e => {
                let filter = editFormData && editFormData.project_technology && editFormData.project_technology.filter(x => x.status === false && x.id === e.value)
                if (filter && filter.length > 0) {
                    const filteredPeople = inactiveTech.filter((item) => item.value !== filter[0].id);
                    setInactiveTech(filteredPeople)
                }
                return technologyArr.push(e.value)
            })
            setSelectTechnology(evt)
        } else {
            setSelectTechnology("")
        }
    }

    //  Update The Resource List(Assignees) of Projects
    const updateProjectResources = async (projectId) => {
        let ProjectRoleArray = []
        projectRoles && projectRoles.length > 0 && projectRoles.map((ele) => {
            ProjectRoleArray.push({
                role: ele.id,
                resource: ele.resource.map((itm) => {
                    return itm.value
                })
            })
            return ele
        })
        const submitData = {
            "project": projectId,
            // "resource": formData.assignedResources,
            "roles": ProjectRoleArray,
            "status": true,
            "created_by": userId
        }
        const res = await api.post("/resources/resource-project", submitData);
        if (res && res.data && res.data.status === 200) {
            return true
        } else {
            toast.error(res.data.message);
            return false;
        }
    }

    const loadProjectTypes = async () => {
        const data = { "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" }
        const res = await api.get(`/mst/project-type?where=${JSON.stringify(data)}`)
        if (res && res.data && res.data.data.rows) {
            setProjectTypes(res.data.data.rows)
        }
    }

    const loadResources = async (datas = null) => {
        const qParam = { "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" };
        const res = await api.get(`resources/main?where=${JSON.stringify(qParam)}`)
        let array = []
        if (res && res.data && res.data.data && res.data.data.rows) {
            // setResources(res.data.data.rows);
            res.data.data.rows.map(e => { return array.push({ value: e.id, label: e.name, name: "assignedResources" }) })
            array.length > 0 && setResourcesData(array)
            array.length > 0 && setSelectFilterResource(array)
            if (datas) {
                let newArray = structuredClone(array)
                let resourcesArray = []
                datas.map(x => {
                    let filter = x.resource.filter(y => y.value)
                    if (filter && filter.length > 0) {
                        // eslint-disable-next-line
                        filter.map((e => {
                            resourcesArray.push(e)
                        }))
                    }
                    return x
                })

                let UpdateResourceArr = []
                UpdateResourceArr = newArray.filter(i => !resourcesArray.some(j => j.value === i.value));
                setSelectFilterResource(UpdateResourceArr)
            }
        }
    }

    const loadProjectResources = async (projectId) => {
        const qParam = { "filter": { "status": { "value": "True", "operation": "eq" }, "project": { "value": projectId, "operation": "eq" } }, "no_limit": "True" };
        const res = await api.get(`/resources/resource-project-role-wise?where=${JSON.stringify(qParam)}`)
        if (res && res.data && res.data.data && res.data.data) {
            let resourceArr = []
            let response = res.data.data
            response.map((element, index) => {
                // for show resource edit section
                element['disabled'] = true
                element['label'] = element.role
                element['value'] = element.role_id
                element['role_name'] = element.role
                element['id'] = element.role_id
                element.resource.map((ele) => {
                    let nameIs = ele.name
                    ele['parent_index'] = index
                    ele['value'] = ele.id
                    ele['label'] = nameIs
                    ele['name'] = "assignedResources"
                    return ele
                })
                return element
            })

            loadResources(response)

            setSelectResourcesArray(response)
            setProjectResourceformValues({ ...projectResourceformValues, project_role: response })
            setAssignedResources(resourceArr);
            setProjectRoles(response)

        }
        // previous code 
        // const res = await api.get(`/resources/resource-project?where=${JSON.stringify(qParam)}`)
        // if (res && res.data && res.data.data && res.data.data.rows) {
        //     const resourceArray = res.data.data.rows.map((element) => {
        //         return element.resource
        //     })
        //     setAssignedResources(resourceArray);
        // }
    }

    useEffect(() => {
        dispatch(workFlowList())
        dispatch(getDepartmentList())
        loadResources()
        loadProjectTypes()
        dispatch(projectRoleDropDown())
        editFormData && editFormData.id && loadProjectResources(editFormData.id)
        editFormData && editFormData.department_id && dispatch(getDepartmentDomains(
            {
                "filter": {
                    "status": { "value": "True", "operation": "eq" },
                    "department_id": { "value": editFormData.department_id, "operation": "eq" }
                }, "no_limit": "True"
            }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dispatch(skilOption(
            {
                "filter": {
                    "status": { "value": "True" }
                }
            }
        ))
        // editFormData && editFormData.technology && setSelectTechnology(editFormData.technology)
        if (editFormData && (editFormData.technology || editFormData.project_technology)) {
            let technologyArray = []
            let inactiveTechnologyArray = []
            if (editFormData && editFormData.project_technology) {
                editFormData.project_technology.length > 0 && editFormData.project_technology.map(e => {
                    if (e.status === true) {
                        return technologyArray.push({
                            label: e.name,
                            value: e.id,
                            name: "technology"

                        })
                    } else {
                        return inactiveTechnologyArray.push({
                            label: e.name,
                            value: e.id,
                            name: "technology",
                            status: e.status

                        })
                    }
                })

            }


            // editFormData.technology.length > 0 && editFormData.technology.map(e => {
            //     return technologyArray.push({
            //         label: e.name,
            //         value: e.id,
            //         name: "technology"

            //     })
            // })
            setSelectTechnology(technologyArray);
            setInactiveTech(inactiveTechnologyArray)
        }
        // eslint-disable-next-line
    }, [])

    const addMoreProjectRole = (evt) => {
        let array = []
        if (evt && evt.length > 0) {
            setProjectResourceformValues({ ...projectResourceformValues, project_role: evt })
        } else {
            setProjectResourceformValues({ ...projectResourceformValues, project_role: "" })
        }

        evt.map(e => {
            let filterData = projectRoles.filter((item) => item.id === e.id)
            return array.push({
                role: e.role_name,
                id: e.id,
                resource: filterData.length > 0 ? filterData[0].resource : [],
                disabled: filterData.length > 0 ? true : false
            })
        })
        //   Added a separate object for admin with userIdd and userLable
        // array.push({
        //   role: "Admin",
        //   id: 1,
        //   resource: [],
        //   disabled: false,
        // });   


        let resourceArr = []
        let newProjectRoleData = structuredClone(projectRoles)

        newProjectRoleData.map((itm) => {

            resourceArr = selectFilterResource.filter(i => !itm.resource.some(j => j.value === i.value))
            return itm
            
        })
        evt && evt.length > 1 ? setSelectFilterResource(resourceArr) : setSelectFilterResource(resourcesData)
        setTimeout(() => {
            setProjectRoles(array)
        }, 500);
    }

    const removeProjectRole = (i) => {
        const updateformData = projectRoles.filter((item, index) => index !== i);
        const updateFormValues = projectResourceformValues && projectResourceformValues.project_role.filter((item, index) => index !== i);

        if (updateFormValues && updateFormValues.length > 0) {
            setProjectResourceformValues({ ...projectResourceformValues, project_role: updateFormValues })
        } else {
            setProjectResourceformValues({ ...projectResourceformValues, project_role: "" })
        }

        let firstIndex = i - 1
        if (firstIndex >= 0) {
            updateformData[i - 1]['disabled'] = false
        }
        setProjectRoles(updateformData)

        let resourcessArray = []
        updateformData.map(x => {

            let filter = x.resource.filter(y => y.value)
            if (filter && filter.length > 0) {
                // eslint-disable-next-line
                filter.map((e => {
                    resourcessArray.push(e)
                }))
            }
            return x
        })

        let UpdateResourceArr = []
        UpdateResourceArr = resourcesData.filter(i => !resourcessArray.some(j => j.value === i.value));
        setSelectFilterResource(UpdateResourceArr)
        delete selectResourcesArray[i]
    }

    // const addMoreResources = (evt, i) => {
    //     let array = []
    //     let filterProjectRole = projectRoles
    //     setSelectResourcesArray({ ...selectResourcesArray, [i]: { parent_index: i, resource: evt } })

    //     evt.map(e => {
    //         return array.push({
    //             parent_index: i,
    //             label: e.label,
    //             value: e.value,
    //         })
    //     })
    //     const updateDetails = filterProjectRole.map((e, index) => {
    //         if (index === i) {
    //             e['resource'] = array
    //         }
    //         return e
    //     })
    //     setProjectRoles(updateDetails)
    //     console.log(updateDetails)
    // }
    console.log(selectResourcesArray)
    console.log(projectRoles)


    const addMoreResources = (evt, i) => {
        let array = [];
        let filterProjectRole = projectRoles;
        setSelectResourcesArray({ ...selectResourcesArray, [i]: { parent_index: i, resource: evt } });
        evt.map((e) => {
            return array.push({
                parent_index: i,
                label: e.label,
                value: e.value,
            });
        });

        const updateDetails = filterProjectRole.map((e, index) => {
            if (index === i) {
                e["resource"] = array;

                if (e.role === "Admin" || e.role === "admin") {
                    e["resource"].push({ parent_index: i, label: userLable, value: userIdd });
                }
            }
            return e;
        });

        setProjectRoles(updateDetails);
    };


    const checkResourcesArray = () => {
        let is_checked = false
        projectRoles && projectRoles.length > 0 && projectRoles.map((item, i) => {
            if (item.resource && item.resource.length === 0) {
                is_checked = true
            }

        })
        return is_checked
    }


    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />
                <div className="top-headings">
                    <h3> {(formData && formData.id) ? "Edit Project" : "Add new Project"} </h3>
                    <Tooltip title="Back">
                        <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                <ToastContainer />
                <form onSubmit={handleSubmit}>
                    {((editFormData && editFormData.project_name) || !editFormData) &&
                        <div className="add-new-user-form">
                            {loading && <LoaderForm />}
                            <div className="formInput50">
                                <label >
                                    Project Name
                                </label>
                                <input
                                    name={"project_name"}
                                    disabled={editFormData && editFormData.project_name}
                                    defaultValue={formData && formData.project_name}
                                    onChange={handleChange}
                                    required={true}
                                    type="text"
                                    placeholder="Project Name"
                                />
                            </div>

                            <div className="formInput50">
                                <label >
                                    Project Type
                                </label>
                                <Select
                                    className="basic-single"
                                    placeholder="Select"
                                    defaultValue={formData && formData.project_type_id ? formData.project_type_default : ""}
                                    isSearchable={true}
                                    name={"project_type_id"}
                                    onChange={handleChange}
                                    options={
                                        projectTypes.map((option) => {
                                            return { label: option.project_type_name, value: option.id, name: "project_type_id" }
                                        })
                                    }
                                />

                            </div>
                            <div className="formInput">
                                <label >
                                    Select Industry
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    divstyle={{ flexBasis: '100%' }}
                                    placeholder={'Select Industry'}
                                    onChange={(evt) => {
                                        handleChange(evt, "industry");
                                    }}
                                    defaultValue={formData.industry}
                                    // value={formData.industry}
                                    isSearchable={true}
                                    name={'industry'}
                                    options={
                                        industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                            return {
                                                label: option.industry_name,
                                                value: option.id,
                                                name: 'industry'
                                            }
                                        })
                                    }
                                />
                                {/* {errStatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>} */}
                            </div>

                            {
                                !formData.is_jira_project && formData.industry &&
                                <>
                                    <div className="formInput50">
                                        <label >
                                            Department
                                        </label>
                                        <Select
                                            className="basic-single"
                                            placeholder="Select"
                                            defaultValue={formData && formData.department_id ? formData.department_id : ""}
                                            isSearchable={true}
                                            name={"department_id"}
                                            onChange={(evt) => {
                                                handleChange(evt, "department_id");

                                            }}
                                            options={department_List.map(e => ({
                                                label: e.label,
                                                value: e.value,
                                                name: "department_id"
                                            }))}
                                        />
                                    </div>
                                    <div className="formInput50">
                                        <label >
                                            Domains
                                        </label>
                                        <Multiselect
                                            isObject={true}
                                            onRemove={(evt) => { handleChange(evt, "domains_ids") }}
                                            onSelect={(evt) => { handleChange(evt, "domains_ids") }}

                                            displayValue={"label"}
                                            selectedValues={formData && formData.domains_ids ? formData.domains_ids : ""}
                                            options={departmentDomainsList.map(e => ({ id: e.domain.id, label: e.domain.name, name: "domains_ids", value: e.domain.id }))}
                                        />
                                    </div>
                                    <div className="formInput50">
                                        <label >
                                            Workflow
                                        </label>
                                        <Select
                                            className="basic-single"
                                            placeholder="Select"
                                            defaultValue={formData.workflow_id ? formData.workflow_id : ""}
                                            isSearchable={true}
                                            name={"workflow_id"}
                                            onChange={handleChange}
                                            options={workflows.map(e => ({ id: e.id, label: e.name, name: "workflow_id", value: e.id }))}
                                        />
                                    </div>
                                </>
                            }


                            {
                                editFormData &&
                                <div className="formInput50">
                                    <label >
                                        Is Jira Project
                                    </label>
                                    <select
                                        name={"is_jira_project"}
                                        defaultValue={formData && formData.is_jira_project}
                                        disabled={true}
                                        onChange={handleChange}
                                        required={true}
                                    >
                                        {formData && formData && !formData.is_jira_project && <option>Select</option>}
                                        <option value={true} label="">Yes</option>
                                        <option value={false} label="">No</option>
                                    </select>
                                </div>
                            }


                            <div className="formInput50">
                                <label >
                                    Status
                                </label>
                                <select
                                    name={"status"}
                                    defaultValue={formData && formData.status}
                                    onChange={handleChange}
                                    required={true}
                                >
                                    {!formData.status && <option>Select</option>}
                                    <option value={true} label="">Active</option>
                                    <option value={false} label="">Inactive</option>
                                </select>
                            </div>
                            {inactiveTech && inactiveTech.length > 0 &&
                                <div className="formInput50">
                                    <label>Inactive Technology </label>
                                    <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Select Technogogy"
                                        value={inactiveTech}
                                        isSearchable={true}
                                        name={"technology"}
                                        isDisabled={true}
                                        options={
                                            skils && skils.map((option) => {
                                                return { label: option.label, value: option.value, name: "technology" }
                                            })
                                        }
                                    //onChange={handleChangeOnTechnology}
                                    />
                                </div>
                            }
                            <div className="formInput50">
                                <label> Skills </label>
                                <Select
                                    isMulti
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder="Select Skills"
                                    value={selectTechnology}
                                    isSearchable={true}
                                    name={"technology"}
                                    options={
                                        skils && skils.map((option) => {
                                            return { label: option.label, value: option.value, name: "technology" }
                                        })
                                    }
                                    onChange={handleChangeOnTechnology}
                                />
                            </div>

                            <div className="formInput50">
                                <InputMultiSelectField
                                    divstyle={{ flexBasis: '100%' }}
                                    label={`Project Role ${checkResourcesArray() === true ? '(Please add resources then you can add project role)' : " "}`}
                                    name={"project_role"}
                                    placeholder={"Project Role"}
                                    onRemove={addMoreProjectRole}
                                    onSelect={addMoreProjectRole}
                                    defaultValue={projectResourceformValues.project_role}
                                    optionList={projectRolesList || []}
                                    optionLevel={['role_name']}
                                    optionValue={'id'}
                                    // errStatus={errStatus}
                                    // formErr={formErr.job_skillErr}
                                    disablePreSelectedValues={true}
                                    disabled={checkResourcesArray() === true ? true : false}
                                />
                            </div>

                            {projectRoles && projectRoles.length > 0 && projectRoles.map((item, i) => {
                                return (
                                    <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>
                                        <div className="formInput" style={{ flexBasis: '36%' }}>
                                            <label> Project Role</label>
                                            <input
                                                disabled
                                                name={"role"}
                                                // onChange={(e) => handleProjectRoleChange(e, i)}
                                                type="text"
                                                placeholder="Project Role"
                                                value={item.role}
                                            />
                                        </div>

                                        <div className="formInput" style={{ flexBasis: '48%' }}>
                                            <label> Resources </label>
                                            <Multiselect
                                                isObject={true}
                                                onRemove={(evt) => { addMoreResources(evt, i) }}
                                                onSelect={(evt) => { addMoreResources(evt, i) }}
                                                displayValue={"label"}
                                                selectedValues={selectResourcesArray && selectResourcesArray[i] ? selectResourcesArray[i].resource : []}
                                                options={selectFilterResource}
                                                placeholder={"Select Resources"}
                                            // disable={item.disabled === true ? true : false}
                                            />
                                        </div>

                                        <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                            {
                                                <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }}
                                                    onClick={() => removeProjectRole(i)}

                                                />
                                            }

                                        </div>
                                    </div>
                                )
                            })
                            }

                            <div className="formInput100">
                                <InputEditorField
                                    label={"Description"}
                                    defaultValue={formData && formData.description}
                                    placeholder="Description"
                                    name="description"
                                    onChange={(evt) => handleDescriptionContent(evt, "description")}
                                   
                                // errStatus={errStatus}
                                // formErr={formErr.descriptionErr}
                                />
                            </div>
                        </div>
                    }

                    <button type="submit" disabled={loading} className="btn btn-submit">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default AddProject;