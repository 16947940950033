import React, { useEffect, useRef, useState } from "react";
import "../hiring.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import LocalStorageDataService from "../../../components/common-function/GetLocalstorage";
import { Button, Step, StepLabel, Stepper, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from "react-redux";
import { getClientList, getDepartmentList, getIndustryList, getSpecialisationList } from "../../../redux/features/hiringRequestSlice";
import { getAllContractTypeList, getAllCurrencyList, getAllHiringModeList, getAllJobLocationList, getAllMoreSpecificationList, getAllQualificationList, getAllUrgencyList, getAllVacancyList, getAllWorkingModeList, getpymenttype, hiringAttachFile, hiringRequestCreate, hiringRequestUpdate, removeHiringAttachFile } from "../../../redux/features/masterSlice";
import { STATUSES } from "../../../redux/common-status/constant";
import StepFormTwo from "./StepFormTwo";
import StepFormThree from "./StepFormThree";
import dataService from "../../../redux/services/data.service";
import StepFormOne from "./StepFormOne";
import LoaderForm from "../../common-function/LoaderForm";
import StepFormFive from "./StepFormFive";

const { REACT_APP_API_BASEURL } = process.env;

const formErrData = Object.freeze({
    // step 1
    clientErr: 'Client is required',
    industryErr: "Industry is required",
    departmentErr: "Department is required",
    specialisationErr: "Technology/Specialisation is required",

    // step 2
    budgetFormErr: "Salary budget from is required",
    budgetToErr: "Salary budget to is required",
    paymentTypeErr: "Payment type is required",
    currenyErr: "Curreny is required",
    hiringModeErr: "Hiring mode is required",

    //step 3
    roleErr: 'Role name is required',
    numOfVacancyErr: "Number of vacancy is required",
    vacancyTypeErr: "Vacancy type is required",
    workingModeErr: "Working mode is required",
    jobLocationErr: "Job location is required",
    urgencyErr: "Urgency is required",
    relevantExpErr: "Relavant experience is required",
    technologiesErr: "Technology is required",
    jobResErr: "Job responsibility is required",
    jobDesErr: "Job description is required",
    expETAErr: "Expected ETA is required",
    moreSpecErr: "More specification is required",
    contractTypeErr: "Contract type is required",
    qualicationErr: "Education Qualification is required",

    //step 5 
    headErr: "Head is required",
    clientInterviewerErr: "Client interviewer is required",

});

const initialFormValues = Object.freeze({
    //step 1

    client: "",
    industry: "",
    department: "",
    specialistation_id: "",
    skill_data: "",

    //step 2
    client_budget_from: "",
    client_budget_to: "",
    currency: "",
    payment_type: "",
    hiring_mode: "",

    // step 3
    role: "",
    number_of_vacancies: "",

    type_of_vacancy: "",
    contract_type: "",
    hours_per_day: null,
    expected_duration_in_months: null,

    urgency: "",
    working_mode: "",
    job_location: "",
    // job_responsibilities: "",
    job_description: "",
    add_more_specifications: "",
    // expected_ETA_in_months: 0,
    // expected_ETA_in_days: 0,
    relevant_experience_expected_from: 0,
    relevant_experience_expected_to: 0,
    total_experience_to: 0,
    total_experience_from: 0,

    attachment: "",
    status: true,

    education_qualification: "",
    expected_ETA: 0,
    urgency_unit: "",

    //step 5
    head_ids: "",
    client_interviewer: "",

});

var experienceYearArr = [];
for (let i = 0; i <= 50; i++) {
    experienceYearArr.push(i);
}

const timeTotalToArray = (i) => {
    var timeArrayForTo = [];
    for (i; i <= 50; i++) {
        timeArrayForTo.push(i);
    }
    return timeArrayForTo
}
const timeRelevantToArray = (i) => {
    var timeArrayForTo = [];
    for (i; i <= 50; i++) {
        timeArrayForTo.push(i);
    }
    return timeArrayForTo
}

function HiringRequest({ title }) {

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    const location = useLocation();
    const formStep = location?.state?.formStep || null;
    const submitFinaleData = location?.state?.submitFinaleData || ""

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(initialFormValues)
    const [finalData, setFinalData] = useState(initialFormValues)

    const [formErr, setFormErr] = useState(formErrData);

    const [errStatus, setErrStatus] = useState(false);
    const [errStatus1, setErrStatus1] = useState(false);
    const [errStatus2, setErrStatus2] = useState(false);
    const [errStatus4, setErrStatus4] = useState(false);

    const [reload, setReload] = useState(false);

    const [clientId, setClientId] = useState(0);
    const [industryId, setIdustryId] = useState(0);
    const [departmentId, setDepartmentId] = useState(0);

    const [showContractType, setShowContractType] = useState(false);
    const [showExpectedHour, setShowExpectedHour] = useState(false);
    // const [showTextBox, setShowTextBox] = useState(false);


    const [validationErr, setValidationErr] = useState(false);
    const [ID, setID] = useState('')

    const [specialisationSkills, setSpecialisationSkills] = useState([])
    const [skillData, setSkillData] = useState([])

    //file
    const MAX_COUNT = 10;
    const uploadInputRef = useRef(null);
    const [fileErr, setFileErr] = useState("")
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [removeFileStatus, setRemoveFileStatus] = useState(false);

    const { status, error, client_List, industry_List, department_List, specialisation_List, } = useSelector(state => state.hiringRequest)
    const { vacancy_AllList, workingmode_AllList, urgencyType_AllLists, specificationOptionList, job_location_AllList,
        currency_AllList, paymenttype_List, hiring_mode_AllList, contracttype_AllList,
        hiring_attach_file_Remove, status_Remove, qualification_AllList,
    } = useSelector(state => state.master)

    const [description, setDescription] = useState("")

    const [totalExperienceTo, setTotalExperienceTo] = useState(experienceYearArr)
    const [relevanExperienceTo, setRelevanExperienceTo] = useState(experienceYearArr)

    // edit 
    const loadDataById = async () => {
        setReload(true)
        const res = await dataService.hiring_request_edit(id).catch(error => {
            toast.error(error.message)
            setReload(false)
            return error;
        })
        let technologyArray = []
        let addMoreSpecificationArray = []
        let addMoreSpecificationFinalArray = []

        let educationArray = []
        let educationArrayFinalArray = []

        let skillDataArray = []
        let headDataArray = []
        let clientInterviewerArray = []
        let headfinalArray = []

        if (res && res.data && res.data.data) {
            const editData = res.data.data

            if (editData && editData.skills_tech_data) {
                editData.skills_tech_data.length > 0 && editData.skills_tech_data.map(e => {
                    return technologyArray.push({
                        label: e.technology && e.technology.name,
                        value: e.technology && e.technology.id,
                        name: "specialistation_id"
                    })
                })

                editData.skills_tech_data.length > 0 && editData.skills_tech_data.map(e => {
                    return skillDataArray.push({
                        technology_id: e.technology && e.technology.id,
                        skill_data: e.skills.map((item) => {
                            return {
                                label: item.skill_name,
                                value: item.id,
                                technology_id: e.technology.id,
                                name: "skill_data"
                            }
                        })
                    })
                })
                const result = skillDataArray.reduce((obj, itm) => ({ ...obj, [itm.technology_id]: itm }), {})
                setSpecialisationSkills(technologyArray)
                setSkillData(result)
            }

            if (editData && editData.add_more_specifications_data) {
                editData.add_more_specifications_data.length > 0 && editData.add_more_specifications_data.map(e => {
                    return addMoreSpecificationArray.push({
                        label: e.specification_name_key,
                        value: e.id
                    })
                })
                editData.add_more_specifications_data.map(e => addMoreSpecificationFinalArray.push({ 'id': e.id }))
            }

            if (editData && editData.education_qualification_data) {
                editData.education_qualification_data.length > 0 && editData.education_qualification_data.map(e => {
                    return educationArray.push({
                        label: e.education_qualification_name,
                        value: e.id
                    })
                })
                editData.add_more_specifications_data.map(e => educationArrayFinalArray.push({ 'id': e.id }))
            }

            if (editData && editData.head_ids_data) {
                editData.head_ids_data.length > 0 && editData.head_ids_data.map(e => {
                    return headDataArray.push({
                        label: e.interview_feedback_head && e.interview_feedback_head.head_name,
                        value: e.interview_feedback_head_id,
                        name: "head_ids"
                    })
                })
            }

            if (editData && editData.head_ids_data && editData.head_ids_data.length > 0) {
                editData.head_ids_data.map(e => headfinalArray.push({ 'id': e.interview_feedback_head_id }))
            }


            if (editData && editData.client_interviewer_data) {
                editData.client_interviewer_data.length > 0 && editData.client_interviewer_data.map(e => {
                    return clientInterviewerArray.push({
                        label: e.name,
                        value: e.id
                    })
                })
            }

            if (editData && editData.type_of_vacancy && editData.type_of_vacancy.vacancy_type_key && (editData.type_of_vacancy.vacancy_type_key.match('part-time') || editData.type_of_vacancy.vacancy_type_key.match('parttime'))) {
                setShowContractType(false)
                setShowExpectedHour(true)
            } else if (editData.type_of_vacancy && editData.type_of_vacancy.vacancy_type_key.match('contractual')) {
                setShowContractType(true)
                setShowExpectedHour(false)
            }

            let newTotalExp = editData.relevant_experience_expected_from ? editData.relevant_experience_expected_from : 0
            let newTotalReleventExp = editData.total_experience_from ? editData.total_experience_from : 0
            let addNewTotalExp = newTotalExp === 0 ? 0 : newTotalExp + 1
            let addNewTotalReleventExp = newTotalReleventExp === 0 ? 0 : newTotalReleventExp + 1

            setTotalExperienceTo(timeTotalToArray(addNewTotalExp))
            setRelevanExperienceTo(timeRelevantToArray(addNewTotalReleventExp))

            if (editData) {
                dispatch(getIndustryList(editData.client_id))
                dispatch(getDepartmentList({ client_id: editData.client_id, industry_id: editData.industry_id }))
                dispatch(getSpecialisationList({ client_id: editData.client_id, industry_id: editData.industry_id, department_id: editData.department_id }))

                setFormValues({
                    // step 1
                    client: { label: editData.client && editData.client.name, value: editData.client_id, is_internal: editData.client?.is_internal },
                    industry: { label: editData.industry && editData.industry.industry_name, value: editData.industry_id },
                    department: { label: editData.department && editData.department.name, value: editData.department_id },
                    specialistation_id: technologyArray,

                    //step 2
                    client_budget_from: editData.client_budget_from,
                    client_budget_to: editData.client_budget_to,
                    currency: { label: editData.currency && editData.currency.currency_name, value: editData.currency_id },
                    payment_type: { label: editData.payment_type && editData.payment_type.name, value: editData.payment_type_id },
                    hiring_mode: { label: editData.hiring_mode && editData.hiring_mode.name, value: editData.hiring_mode_id },

                    // step 3
                    role: editData.role,
                    number_of_vacancies: editData.number_of_vacancies,
                    type_of_vacancy: { label: editData.type_of_vacancy && editData.type_of_vacancy.vacancy_type, value: editData.type_of_vacancy_id },
                    contract_type: { label: editData.contract_type && editData.contract_type.name, value: editData.contract_type_id },
                    hours_per_day: editData.hours_per_day ? editData.hours_per_day : null,
                    expected_duration_in_months: editData.expected_duration_in_months ? editData.expected_duration_in_months : null,

                    working_mode: { label: editData.working_mode && editData.working_mode.working_mode, value: editData.working_mode_id },
                    // urgency: { label: editData.urgency && editData.urgency.urgency_type, value: editData.urgency_id },
                    job_location: { label: editData.job_location && editData.job_location.job_location, value: editData.job_location_id },
                    relevant_experience_expected: editData && editData.relevant_experience_expected,
                    job_description: editData.job_description,

                    // job_responsibilities: editData.job_responsibilities,
                    // expected_ETA_in_months: editData.expected_ETA_in_months,
                    // expected_ETA_in_days: editData.expected_ETA_in_days,
                    // relevant_experience_expected_in_years: editData.relevant_experience_expected_in_years,
                    // relevant_experience_expected_in_months: editData.relevant_experience_expected_in_months,

                    relevant_experience_expected_from: editData.relevant_experience_expected_from,
                    relevant_experience_expected_to: editData.relevant_experience_expected_to,
                    total_experience_to: editData.total_experience_to,
                    total_experience_from: editData.total_experience_from,

                    add_more_specifications: addMoreSpecificationArray,
                    add_more_specifications_label: addMoreSpecificationFinalArray,

                    attachment: editData.attachment ? editData.attachment : null,
                    status: editData.status,
                    hiring_status: editData.hiring_status,

                    // urgency_unit: { label: editData.urgency_unit && editData.urgency_unit.urgency_type, value: editData.urgency_unit_id },
                    urgency_unit: editData.urgency_unit_id,
                    expected_ETA: editData.expected_ETA,
                    education_qualification: educationArray,
                    education_qualification_label: educationArrayFinalArray,

                    //step 5
                    client_interviewer: clientInterviewerArray,
                    head_ids: headDataArray,
                    head_label: headfinalArray
                })
            }

            if (editData.job_description) { setDescription(editData.job_description) }
            setReload(false)
        } else {
            setReload(false)
            toast.error(res?.data?.message)
        }
    }
    // edit section end 

    useEffect(() => {
        if (id) {
            setID(id)
            loadDataById()
        }
        // step 1
        dispatch(getClientList())

        //step 2
        dispatch(getAllCurrencyList())
        dispatch(getpymenttype())
        dispatch(getAllHiringModeList())

        // step 3
        dispatch(getAllVacancyList())
        dispatch(getAllWorkingModeList())
        dispatch(getAllMoreSpecificationList())
        dispatch(getAllUrgencyList())
        dispatch(getAllJobLocationList())

        dispatch(getAllContractTypeList())
        dispatch(getAllQualificationList())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // handle change skill
    const handleSkillChange = (tech_id, evt, index = null) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, "skill_data": "" });
        } else {
            setFormValues({ ...formValues, "skill_data": "" });
        }
        setSkillData({
            ...skillData,
            [tech_id]: {
                'technology_id': tech_id,
                'skill_data': evt
            }
        })
    }

    //  add more technology / specification
    const addMoreTechnology = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, specialistation_id: evt })
            setSpecialisationSkills(evt)
        } else {
            setFormValues({ ...formValues, specialistation_id: "" })
            setSpecialisationSkills([])
            setSkillData([])
        }
    }

    // remove skills
    const removeSkill = (id) => {
        setReload(true)

        const updateformData = specialisationSkills.filter((item, index) => item.value !== id);
        const updateFormValues = formValues && formValues.specialistation_id.filter((item, index) => item.value !== id);
        if (updateFormValues && updateFormValues.length > 0) {
            setFormValues({ ...formValues, specialistation_id: updateFormValues })
        } else {
            setFormValues({ ...formValues, specialistation_id: "" })
            setSkillData([])
            setSpecialisationSkills([])
        }

        if (updateformData && updateformData.length > 0) {
            let dataKeys = Object.entries(skillData)
            let newFinalData = dataKeys.filter((ele) => ele[1].technology_id !== id)
            let skillArrays = newFinalData.map((item) => {
                return {
                    'technology_id': item[1].technology_id, 'skill_data': item[1].skill_data
                }
            })

            const result = skillArrays.reduce((obj, itm) => ({ ...obj, [itm.technology_id]: itm }), {})
            setSkillData(result)
            setSpecialisationSkills(updateformData)
            setTimeout(() => {
                setReload(false)
            }, 2);
        } else {
            setSpecialisationSkills([])
            setSkillData([])
            setReload(false)
        }
    }

    // step 1 submit section start
    const onSubmitStep1 = async (e, type) => {
        if (type === null) {
            e.preventDefault();
        }

        let specArray = []
        if (formValues.specialistation_id && formValues.specialistation_id.length > 0) {
            formValues.specialistation_id.map(e => e.value ? specArray.push(e.value) : specArray.push(e))
        }

        // prepare skill data 
        let dataKeys = Object.entries(skillData)
        const newSkillData = dataKeys.map((item, i) => {
            return { 'technology_id': item[0], "skill_ids": item[1].skill_data.map((ele) => ele.value) }
        })

        const addvalues = {
            // ...formValues,
            client: formValues.client && formValues.client.label ? formValues.client.value : formValues.client,
            industry: formValues.industry && formValues.industry.label ? formValues.industry.value : formValues.industry,
            department: formValues.department && formValues.department.label ? formValues.department.value : formValues.department,
            // specialistation_id: specArray,
            skills_data: newSkillData,
            status: true,
            hiring_status: 0,
        };
        setFinalData(addvalues)

        if (formValues.client === "" || formValues.industry === "" || formValues.department === "" || formValues.specialistation_id === "") {
            setErrStatus(true)
            setFormErr(formErrData)
            return
        }
        if (formValues.client !== "" && formValues.industry !== "" && formValues.department !== "" && formValues.specialistation_id !== "") {
            setErrStatus(false)
            setReload('submit')
            let res;
            let result;
            if (ID) {
                res = await dispatch(hiringRequestUpdate({ ID, addvalues }))
                result = await res.payload
                setValidationErr(false)
            } else {
                res = await dispatch(hiringRequestCreate(addvalues))
                result = await res.payload
            }
            if (result.status === 200) {
                setValidationErr(false)
                result.data && setID(result.data.id)
                toast.success(result.message)
            } else {
                setValidationErr(true)
                toast.error(result.message)
                setActiveStep(0)
            }
            setReload(false)
        }
    }

    // step 2 submit section start
    const onSubmitStep2 = async (e, type) => {
        if (type === null) {
            e.preventDefault();
        }

        // prepare skill data 
        let dataKeys = Object.entries(skillData)
        const newSkillData = dataKeys.map((item, i) => {
            return { 'technology_id': item[0], "skill_ids": item[1].skill_data.map((ele) => ele.value) }
        })

        const addvalues = {
            // ...formValues,
            client: formValues.client && formValues.client.label ? formValues.client.value : formValues.client,
            industry: formValues.industry && formValues.industry.label ? formValues.industry.value : formValues.industry,
            department: formValues.department && formValues.department.label ? formValues.department.value : formValues.department,
            skills_data: newSkillData,

            client_budget_from: formValues.client_budget_from,
            client_budget_to: formValues.client_budget_to,
            currency: formValues.currency && formValues.currency.label ? formValues.currency.value : formValues.currency,
            payment_type: formValues.payment_type && formValues.payment_type.label ? formValues.payment_type.value : formValues.payment_type,
            hiring_mode: formValues.hiring_mode && formValues.hiring_mode.label ? formValues.hiring_mode.value : formValues.hiring_mode,
            hiring_status: 0
        };

        setFinalData(addvalues)

        if (formValues.client_budget_from === "" || formValues.client_budget_to === "" || formValues.currency === "" || formValues.payment_type === "" || formValues.hiring_mode === "") {
            setErrStatus1(true)
            setFormErr(formErrData)
            return
        }
        if (formValues.client_budget_from !== "" && formValues.client_budget_to !== "" && formValues.currency !== "" && formValues.payment_type !== "" && formValues.hiring_mode !== "") {
            setErrStatus1(false)
            // setFormErr("")
            setReload('submit')
            let res;
            let result;
            if (ID) {
                res = await dispatch(hiringRequestUpdate({ ID, addvalues }))
                result = await res.payload
                setValidationErr(false)

            } else {
                res = await dispatch(hiringRequestCreate(addvalues))
                result = await res.payload
            }

            if (result.status === 200) {
                setValidationErr(false)
                result.data && setID(result.data.id)
                toast.success(result.message)
            } else {
                setValidationErr(true)
                toast.error(result.message)
                setActiveStep(1)
            }
            setReload(false)
        }
    }

    // step 3 submit section start
    const onSubmitStep3 = async (e, type) => {

        if (type === null) {
            e.preventDefault();
        }

        // let specArray = []
        let clientArray = []

        // if (formValues.add_more_specifications && formValues.add_more_specifications.length > 0) {
        //     formValues.add_more_specifications.map(e => e.value ? specArray.push(e.value) : specArray.push(e))
        // }

        if (formValues.client_interviewer && formValues.client_interviewer.length > 0) {
            formValues.client_interviewer.map(e => e.value ? clientArray.push(e.value) : clientArray.push(e))
        }

        // prepare skill data 
        let dataKeys = Object.entries(skillData)
        const newSkillData = dataKeys.map((item, i) => {
            return { 'technology_id': item[0], "skill_ids": item[1].skill_data.map((ele) => ele.value) }
        })

        const addvalues = {
            ...formValues,

            client: formValues.client && formValues.client.label ? formValues.client.value : formValues.client,
            industry: formValues.industry && formValues.industry.label ? formValues.industry.value : formValues.industry,
            department: formValues.department && formValues.department.label ? formValues.department.value : formValues.department,
            skills_data: newSkillData,

            currency: formValues.currency && formValues.currency.label ? formValues.currency.value : formValues.currency,
            payment_type: formValues.payment_type && formValues.payment_type.label ? formValues.payment_type.value : formValues.payment_type,
            hiring_mode: formValues.hiring_mode && formValues.hiring_mode.label ? formValues.hiring_mode.value : formValues.hiring_mode,

            // add_more_specifications: specArray,
            job_description: description,
            job_location: formValues.job_location && formValues.job_location.value ? formValues.job_location.value : formValues.job_location,
            type_of_vacancy: formValues.type_of_vacancy && formValues.type_of_vacancy.value ? formValues.type_of_vacancy.value : formValues.type_of_vacancy,
            urgency: formValues.urgency && formValues.urgency.value ? formValues.urgency.value : formValues.urgency,
            working_mode: formValues.working_mode && formValues.working_mode.value ? formValues.working_mode.value : formValues.working_mode,
            contract_type: formValues.contract_type ? formValues.contract_type.value : formValues.contract_type.label === null ? null : formValues.contract_type,

            hiring_status: id ? formValues.hiring_status : 0,

            created_by: LocalStorageDataService.userDetail().id,
            client_interviewer: clientArray,
            head_ids: formValues.head_label,
            add_more_specifications: formValues.add_more_specifications_label,
            education_qualification: formValues.education_qualification_label

        };
        delete addvalues['add_more_specifications_label']
        delete addvalues['education_qualification_label']
        delete addvalues['specialistation_id']
        delete addvalues['skill_data']
        delete addvalues['head_label']

        setFinalData(addvalues)

        if (formValues.role === "" || formValues.number_of_vacancies === "" || formValues.type_of_vacancy === "" || formValues.working_mode === ""
            || description === "" || formValues.add_more_specifications === "") {
            setErrStatus2(true)
            setFormErr(formErrData)
            return
        }

        if (formValues.role !== "" && formValues.number_of_vacancies !== "" && formValues.type_of_vacancy !== "" &&
            formValues.working_mode !== "" && formValues.job_location !== ""
            && description !== "" && formValues.add_more_specifications !== "") {
            setErrStatus2(false)

            setReload('submit')
            let res;
            if (ID) {
                res = await dataService.hiring_request_update(ID, addvalues).catch(error => {
                    toast.error(error.message)
                    return error;
                })
                setValidationErr(false)
            } else {
                res = await dataService.hiring_request_create(addvalues)
            }

            // file
            var attchForm = new FormData();
            uploadedFiles.forEach((file, i) => {
                attchForm.append(`file`, file, file.name);
            });
            attchForm.append("upload_type", "hiring");

            if (res && res.data && res.data.status === 200) {
                setValidationErr(false)
                toast.success(res?.data?.message);
                if (uploadedFiles && uploadedFiles.length > 0) {
                    attchForm.append("id", res.data?.data?.id);
                    dispatch(hiringAttachFile(attchForm))
                }
            } else {
                setValidationErr(true)
                toast.error(res?.data?.message);
                setActiveStep(2)
            }
            setReload(false)
        }
    }

    // step 5 submit section start
    const onSubmitStep5 = async (e, type) => {
        const addvalues = finalData
        
        if (type === null) {
            e.preventDefault();
        }

        addvalues['head_ids'] = formValues.head_label
        addvalues['hiring_status'] = id ? formValues.hiring_status : 0
        let clientArr = []

        if (formValues.client_interviewer && formValues.client_interviewer.length > 0) {
            formValues.client_interviewer.map(e => e.value ? clientArr.push(e.value) : clientArr.push(e))
        }

        if (clientArr && clientArr.length > 0) {
            addvalues['client_interviewer'] = clientArr;
        } else {
            let newArr = formValues.client_interviewer && formValues.client_interviewer.value ? formValues.client_interviewer.value : formValues.client_interviewer;
            addvalues['client_interviewer'] = [newArr]
        }

        if (formValues.head_ids === "" || !formValues.head_ids) {
            setFormErr(formErrData)
            setErrStatus4(true)
            return
        }
        if (formValues.head_ids !== "") {
            setErrStatus4(false)
            setReload('submit')
            let res;
            let result;
            if (ID) {
                res = await dispatch(hiringRequestUpdate({ ID, addvalues }))
                result = await res.payload
                setValidationErr(false)
            } else {
                res = await dispatch(hiringRequestCreate(addvalues))
                result = await res.payload
            }

            if (result.status === 200) {
                setValidationErr(false)
                result.data && setID(result.data.id)
                toast.success(result.message)
                setTimeout(() => {
                    navigate(`/add-candidate-screening/${ID}`, { state: formValues })
                }, 1200)
            } else {
                setValidationErr(true)
                toast.error(result.message)
                setActiveStep(4)
            }
            setReload(false)
        }
    }

    // const finalSubmit = () => {
    //     if (formValues.head_ids === "" || !formValues.head_ids || !formValues.client_interviewer || formValues.client_interviewer === "") {
    //         setErrStatus4(true)
    //         setFormErr(formErrData)
    //         return
    //     } else {
    //         onSubmitStep5(null, 'type')
    //     }
    // }

    const handleChangeOnSpecialisation = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, "specialistation_id": evt });
        } else {
            setFormValues({ ...formValues, "specialistation_id": "" });
            setSpecialisationSkills([])
        }
    }

    // handle change for single select drop-down
    const handleChangeSelect = (evt) => {
        const { name, value } = evt.target || evt;
        if (evt) {
            if (name === 'client') {
                setClientId(value)
                dispatch(getIndustryList(value))
                setFormValues({ ...formValues, [name]: evt, industry: "", department: "", specialistation_id: "" });
                setSpecialisationSkills([])

            } else if (name === 'industry') {
                setIdustryId(value)
                dispatch(getDepartmentList({ client_id: clientId, industry_id: value }))
                setFormValues({ ...formValues, [name]: evt, department: "", specialistation_id: "" });
                setSpecialisationSkills([])

            } else if (name === 'department') {
                setDepartmentId(value)
                dispatch(getSpecialisationList({ client_id: clientId, industry_id: industryId, department_id: value }))
                setFormValues({ ...formValues, [name]: evt, specialistation_id: "" });
                setSpecialisationSkills([])

            } else if (name === 'type_of_vacancy') {
                setFormValues({ ...formValues, [name]: evt });
                if (evt.key_name && (evt.key_name.match('part-time') || evt.key_name.match('partTime') || evt.key_name.match('part-time'))) {
                    setShowContractType(false)
                    setShowExpectedHour(true)
                } else if (evt.key_name && evt.key_name.match('contractual')) {
                    setShowContractType(true)
                    setShowExpectedHour(false)
                }
            }
            //  else if (name === 'urgency') {
            //     setFormValues({ ...formValues, [name]: evt });
            //     if (evt.value && evt.value === '-1') {
            //         setShowTextBox(true)
            //     } else {
            //         setShowTextBox(false)
            //     }
            // }
            else {
                setFormValues({ ...formValues, [name]: evt });
            }
        } else {
            setClientId(0)
            setIdustryId(0)
            setDepartmentId(0)
        }
    }

    // handle change for input box/textarea
    const handleChangeText = (evt) => {
        const { name, value } = evt.target || evt;
        let newValues = Number(value)
        if (name === 'relevant_experience_expected_from') {
            setRelevanExperienceTo(timeRelevantToArray(newValues + 1))
            setFormValues({ ...formValues, [name]: value, "relevant_experience_expected_to": newValues + 1 });
        } else if (name === 'total_experience_from') {
            setFormValues({ ...formValues, [name]: value, "total_experience_to": newValues + 1 });
            setTotalExperienceTo(timeTotalToArray(newValues + 1))
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    }

    //handle Change for editor / description
    const handleDescriptionContent = (evt, name) => {
        var newContent = evt.editor.getData();
        setDescription(newContent)
    }

    // handle Change for Specification
    const handleChangeOnSpecification = (evt) => {
        let finalArray = []
        if (evt && evt.length > 0) {
            // eslint-disable-next-line
            evt.map((item) => {
                if (item && item.__isNew__) {
                    finalArray.push({
                        value: item.value,
                        new: true
                    })
                } else {
                    finalArray.push({
                        id: item.value,
                    })
                }
            })
            setFormValues({ ...formValues, "add_more_specifications": evt, "add_more_specifications_label": finalArray });
        } else {
            setFormValues({ ...formValues, "add_more_specifications": "" });
        }
    }

    // handle Change for Qualification
    const handleChangeOnQualification = (evt) => {
        let finalArray = []
        if (evt && evt.length > 0) {
            // eslint-disable-next-line
            evt.map((item) => {
                if (item && item.__isNew__) {
                    finalArray.push({
                        value: item.value,
                        new: true
                    })
                } else {
                    finalArray.push({
                        id: item.value,
                    })
                }
            })
            setFormValues({ ...formValues, "education_qualification": evt, "education_qualification_label": finalArray });
        } else {
            setFormValues({ ...formValues, "education_qualification": "" });
        }
    }

    // attachment section start
    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        // eslint-disable-next-line
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length > MAX_COUNT) {
                    setErrStatus(true)
                    setFileErr(`You can only add a maximum of ${MAX_COUNT} files`)
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) {
            setUploadedFiles(uploaded)
        }
    }

    // delete file for local
    function deleteFile(e) {
        const s = uploadedFiles.filter((item, index) => index !== e);
        setUploadedFiles(s);
    }

    // on file change
    const onFileChange = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        handleUploadFiles(chosenFiles);
    }

    //download file 
    const fileDownload = (url) => {
        let ApIURL = REACT_APP_API_BASEURL + url;
        window.open(ApIURL.replace("api//", "api/"));
    };

    //Remove attach file(edit
    const removeAttachFile = (name) => {
        const data = {
            "upload_type": "hiring",
            "id": id,
            "paths": [name]
        }
        dispatch(removeHiringAttachFile(data))
        setRemoveFileStatus('remove')
    }
    if (removeFileStatus === "remove" && status_Remove === `${STATUSES.SUCCESS}_hiring_attach_remove` && hiring_attach_file_Remove && hiring_attach_file_Remove.message) {
        if (hiring_attach_file_Remove.status === 200) {
            toast.success(hiring_attach_file_Remove.message)
            setReload(true)
            loadDataById()
        } else {
            toast.error(hiring_attach_file_Remove.message)
        }
        setRemoveFileStatus(false)
        setReload(true)
    }

    // stepper code start
    const steps = ['Job Configuration', 'Job Comercials', 'Job Details', 'Interviewer Details'];

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        let doNext = false;

        switch (activeStep) {
            case 0:
                if (!formValues.client || !formValues.industry || !formValues.department || formValues.specialistation_id === "") {
                    setErrStatus(true)
                    return
                } else {
                    onSubmitStep1(null, "type")
                    doNext = true;
                }
                break;
            case 1:
                if (!formValues.client_budget_from || !formValues.client_budget_to || formValues.currency === "" || formValues.payment_type === "" || formValues.hiring_mode === "") {
                    setErrStatus1(true)
                    return
                } else {
                    onSubmitStep2(null, "type")
                    doNext = true;
                }
                break;

            case 2:
                if (formValues.role === "" || formValues.number_of_vacancies === "" || formValues.type_of_vacancy === "" ||
                    formValues.working_mode === "" || description === "" || formValues.add_more_specifications === "") {
                    setErrStatus2(true)
                    return
                } else {
                    onSubmitStep3(null, "type")
                    doNext = true;
                }
                break;
            // case 3:
            //     doNext = true;
            //     break;
            default:
                break;

        }

        if (doNext === true && validationErr === false) {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
        }

    };

    const handleBack = () => {
        setErrStatus(false)
        setErrStatus1(false)
        setErrStatus2(false)
        setErrStatus4(false)

        setReload(false)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    // stepper code end 

    //candidate screning test  section start
    const candidateScreeningTest = () => {
        onSubmitStep5(null, 'type')
        // setTimeout(() => {
        //     navigate(`/add-candidate-screening/${ID}`, { state: formValues })
        // }, 500);
    }

    useEffect(() => {
        if(id){
            loadDataById()
        }
        if (formStep !== null) {
            setTimeout(() => {
                setActiveStep(formStep);
                setFinalData(submitFinaleData)
            }, 1000);
        }
    }, [formStep]);

    return (
        <div className="new-hiring">
            <Sidebar />
            <div className="new-hiring-container">
                <Navbar />
                <div className="top-headings">
                    <h3>{title} </h3>
                    <Tooltip title="Back">
                        <Link to={"/roles"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                <ToastContainer />
                <div>
                    <p style={{ padding: "10px" }}> &nbsp; </p>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className="form-hiring">
                        {reload === true && <LoaderForm />}

                        {
                            activeStep === 0 ?
                                <div style={{ minHeight: "400px" }}>
                                    {/* step one form */}
                                    <StepFormOne
                                        client_List={client_List}
                                        submitData={onSubmitStep1}
                                        handleChangeSelect={handleChangeSelect}
                                        handleChangeText={handleChangeText}
                                        formValues={formValues}
                                        errStatus={errStatus}
                                        formErr={formErr}
                                        handleChangeOnSpecialisation={handleChangeOnSpecialisation}
                                        industry_List={industry_List}
                                        department_List={department_List}
                                        specialisation_List={specialisation_List}
                                        addMoreTechnology={addMoreTechnology}
                                        skills={skillData}
                                        removeSkill={removeSkill}
                                        reload={reload}
                                        specialisationSkills={specialisationSkills}
                                        handleSkillChange={handleSkillChange}
                                        showSkillData={skillData}
                                    />
                                </div>
                                : activeStep === 1 ?
                                    <div style={{ minHeight: "400px" }}>
                                        {/* step toe form */}
                                        <StepFormTwo
                                            client_List={client_List}
                                            onSubmitStep2={onSubmitStep2}
                                            handleChangeSelect={handleChangeSelect}
                                            handleChangeText={handleChangeText}
                                            formValues={formValues}
                                            errStatus={errStatus1}
                                            formErr={formErr}
                                            currency_AllList={currency_AllList}
                                            paymenttype_List={paymenttype_List}
                                            hiring_mode_AllList={hiring_mode_AllList}
                                            reload={reload}
                                        />
                                    </div>
                                    : activeStep === 2 ?
                                        <div style={{ minHeight: "400px" }}>
                                            {/* step three form */}
                                            <StepFormThree
                                                client_List={client_List}
                                                submitData={onSubmitStep3}
                                                handleChangeSelect={handleChangeSelect}
                                                handleChangeText={handleChangeText}
                                                formValues={formValues}
                                                errStatus={errStatus2}
                                                formErr={formErr}
                                                vacancy_AllList={vacancy_AllList}
                                                workingmode_AllList={workingmode_AllList}
                                                urgencytype_AllLists={urgencyType_AllLists}
                                                job_location_AllList={job_location_AllList}
                                                specificationOptionList={specificationOptionList}
                                                handleChangeOnSpecialisation={handleChangeOnSpecialisation}
                                                handleChangeOnSpecification={handleChangeOnSpecification}
                                                handleDescriptionContent={handleDescriptionContent}
                                                description={description}
                                                showContractType={showContractType}
                                                contracttype_AllList={contracttype_AllList}
                                                showExpectedHour={showExpectedHour}
                                                // showTextBox={showTextBox}
                                                handleChangeOnQualification={handleChangeOnQualification}
                                                qualification_AllList={qualification_AllList}
                                                totalExperienceTo={totalExperienceTo}
                                                relevanExperienceTo={relevanExperienceTo}
                                                //file
                                                uploadedFiles={uploadedFiles}
                                                deleteFile={deleteFile}
                                                fileErr={fileErr}
                                                onFileChange={onFileChange}
                                                uploadInputRef={uploadInputRef}
                                                removeAttachFile={removeAttachFile}
                                                fileDownload={fileDownload}
                                                id={id}
                                                reload={reload}
                                                removeFileStatus={removeFileStatus}
                                            />
                                        </div>
                                        // : activeStep === 3 ?
                                        //     <div style={{ minHeight: "400px" }}>
                                        //         Step 4
                                        //     </div>
                                        : activeStep === 3 ?
                                            <div style={{ minHeight: "300px" }}>
                                                <StepFormFive
                                                    reload={reload}
                                                    formValues={formValues}
                                                    errStatus={errStatus4}
                                                    formErr={formErr}
                                                    setFormValues={setFormValues}
                                                    submitData={onSubmitStep5}
                                                    handleChangeSelect={handleChangeSelect}
                                                />
                                            </div>
                                            : ""

                        }
                    </div>

                    <div>
                        {allStepsCompleted() ? (
                            <>
                                <p sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </p>
                                <div sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button onClick={handleReset}>Reset</Button>
                                </div>
                            </>
                        ) : (
                            <div className="add-new-user-form" style={{ display: "flex", float: "right", marginBottom: "30px" }} >
                                {activeStep !== 0 && <button
                                    color="inherit"
                                    disabled={activeStep === 0 || reload === true}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                    className={(reload === true || reload === 'submit') ? "submit-disabled-hiring" : "btn btn-submit"}
                                >
                                    Previous
                                </button>}
                                {activeStep !== 3 ? <button
                                    onClick={handleNext}
                                    sx={{ mr: 1 }}
                                    className={(reload === true || reload === 'submit') ? "submit-disabled-hiring" : "btn btn-submit"}
                                    style={{ marginLeft: "20px", marginRight: "20px" }}
                                    disabled={reload === true}
                                >
                                    Next
                                </button> :
                                    <>
                                        {/* <button
                                            onClick={() => finalSubmit()}
                                            sx={{ mr: 1 }}
                                            className={(reload === true || reload === 'submit') ? "submit-disabled-hiring" : "btn btn-submit"}
                                            style={{ marginLeft: "20px", marginRight: "20px" }}
                                        >
                                            Final Submit
                                        </button> */}
                                        <button
                                            onClick={() => candidateScreeningTest()}
                                            sx={{ mr: 1 }}
                                            className={(reload === true || reload === 'submit') ? "submit-disabled-hiring" : "btn btn-submit"}
                                            style={{ marginLeft: "20px", marginRight: "20px", width: "300px" }}
                                        >
                                            Add Candidate Screening Test
                                        </button>
                                    </>
                                }
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}
export default HiringRequest